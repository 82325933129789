import React from "react"
import { graphql } from "gatsby"
import Kontakt from "../../pageTemplates/kontakt"

const Contact = ({ data, location }) => {
  return <Kontakt data={data} location={location} en />
}

export const query = graphql`
  query {
    strapi {
      kontakt {
        meta_en {
          title
          description
        }
        inhalt_en {
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageText {
            id
            text
            __typename
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              caption
              alternativeText
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Contact
